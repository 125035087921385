/* QUOTER */
.quoter-image {
  border-radius: 7%;
}
.quoter-title {
  font-family: 'Poiret One', sans-serif;
  font-weight: 300;
}

/* MIMOOD */
.miMood-info,
.miMood-info a {
  background-color: rgb(193, 193, 212);
  color: #202027;
}

.miMood-image {
  border-radius: 50%;
  border: 1px solid #202027;
}

.miMood-gallery img {
  border: 1px solid #202027;
}


/* BOOKMARKS */
.bookmarks-image {
  top: 100px;
  border-radius: 7px;
}

/* ART BY MOLLY REUSSER */
.molly-info h2 {
  font-size: 30px;
}

.molly-image {
  bottom: -36px;
  border-radius: 3px;
}