.main-image {
  width: 150px;
  margin: auto;
}

.main-image-box-shadow {
  box-shadow: 0 4px 8px rgba(15, 15, 15, 0.63);
}

.vl3 {
  bottom: 20px;
}

.vl4 {
  bottom: 20px;
  left: 42px;
  border-left: 2px dotted rgb(56, 56, 56)
}

.mobile-image {
  width: 148px;
  border-radius: 7px;
  position: relative;
}

.info-container h2 {
  display: inline-block;
  font-size: 42px;
  margin: 0;
}

.tech {
  top: 192px;
}

.mv2 {
  height: 120px;
  left: 415px;
  bottom: 74px;
}

.tech-area {
  grid-column: 4/5;
  grid-row: 1/2;
  margin: auto;
  width: 200px;
  font-size: 32px;
}

.tech-area i {
  margin: 4%;
  display: inline-block;
}