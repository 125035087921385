.about-me-container {
  width: 80%;
  min-width: 800px;
  display: grid;
  grid-template-columns: 80px 500px 1fr 80px;
  grid-template-rows: 460px 28px 1fr;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 8%;
  -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s; /* Firefox < 16 */
   -ms-animation: fadein 0.2s; /* Internet Explorer */
    -o-animation: fadein 0.2s; /* Opera < 12.1 */
       animation: fadein 0.2s;
}

.hl-container {
  grid-column: 2/4;
  grid-row: 2/3;
  display: flex;
  justify-content: center;
  align-content: center;
}

.hl-container .hl {
  margin-top: 3%;
}

.about-portrait {
  grid-column: 2/3;
  grid-row: 1/2;
  max-height: 460px;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(15, 15, 15, 0.63);
}

.contact-links {
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: column;
}

.contact-links * {
  color: rgb(241, 241, 241);
  font-size: 36px;
}

.contact-links a {
  display: inline-block;
}

.about-info {
  grid-column: 3/5;
  grid-row: 1/2;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
}

.about-info p {
  margin-top: 4%;
  margin-bottom: 0;
}

.about-skills {
  grid-column: 2/5;
  grid-row: 3/4;
  display: flex;
  justify-content: space-between;
}

.skills-section {
  width: 200px;
  text-align: left;
}

.skills-section ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.skills-section ul li i {
  display: inline-block;
  margin-right: 10%;
}

.skills-section ul li p {
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .about-me-container {
    display: block;
    min-width: initial;
  }
  .about-portrait {
    max-width: 100%;
    margin-top: 4%;
  }
  .contact-links {
    flex-flow: row;
    margin-top: 4%;
  }
  .contact-links a {
    margin: auto;
  }
  .about-skills {
    display: block;
  }
  .about-info {
    margin-top: 2%;
  }
  .skills-section h2 {
    margin-top: 16%;
    margin-bottom: 0;
  }
  .skills-section ul {
    margin-top: 0;
  }
  .hl-container .hl {
    margin-top: 8%;
  }
}