.gallery-area {
  grid-column: 1/6;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow:  -moz-scrollbars-none;
  -ms-overflow-style: none;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
}

.gallery-area::-webkit-scrollbar { 
  width: 0 !important;
}

.gallery-area img {
  max-height: 400px;
  box-shadow: 0 4px 8px rgba(15, 15, 15, 0.63);
  border-radius: 5px;
  margin: 0 16px;
  margin-bottom: 14px;
  object-fit: contain;
}

.memoryfield-gallery {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .gallery-area {
    margin-top: 4%;
  }
  .gallery-area img {
    max-width: 300px;
  }
}