.projects-container {
  min-height: 490px;
  width: 90%;
  min-width: 800px;
  margin: auto;

  background: 
    linear-gradient(135deg, rgb(19, 19, 22),  25%, transparent 25%) -50px 0,
    linear-gradient(225deg, rgb(19, 19, 22),  25%, transparent 25%) -50px 0,
    linear-gradient(315deg, rgb(19, 19, 22),  25%, transparent 25%),
    linear-gradient(45deg, rgb(19, 19, 22), 25%, transparent 25%);	
  background-size: 2em 2em;
  background-color: rgb(24, 24, 27);

    -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.2s; /* Firefox < 16 */
        -ms-animation: fadein 0.2s; /* Internet Explorer */
         -o-animation: fadein 0.2s; /* Opera < 12.1 */
            animation: fadein 0.2s;
}

.projects-overview {
  background-color: rgb(19, 19, 22);
  margin: 0 auto;
  min-height: 240px;
  text-align: left;
  border-right: 1px solid rgb(56, 56, 56);
  border-left: 1px solid rgb(56, 56, 56);
}

.projects-overview h2 {
  display: inline-block;
  margin: 0;
  font-size: 131px;
  font-family: 'Cantarell', sans-serif;
  text-align: left;
  margin-left: 20px;
  position: relative;
  top: 44px;
  /* background-color: rgb(19, 19, 22); */
  z-index: 100;

  pointer-events: none;
}

.pro-dec-1 {
  opacity: 0.6;

  background: linear-gradient(59deg, #999 40%, transparent 44%) 14px 0, linear-gradient(72deg, transparent 35%, #999 43%), linear-gradient(63deg, transparent 64%, #999 60%, #999 54%, transparent 64%), #444;
  background-size: 17px 18px;

  min-width: 800px;
  margin: auto;
  min-height: 52px;
}

.projects-list {
  margin: auto;
  margin-bottom: 60px;
  position: relative;
}

.project-wrapper {
  margin: 0;
}

.project {
  box-sizing: border-box;

  width: 100%;
  min-height: 32px;
  border: 1px solid rgb(56, 56, 56);
  background-color: rgb(32, 33, 39);
  display: inline-block;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s;

  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

.collapsed:hover {
  cursor: pointer;
}

.expanded:hover {
  cursor: pointer;
}

.project-wrapper:hover .project {
  background-color: rgb(29, 31, 41);
}

.project p {
  font-size: 14px;
  margin-left: 20px;
  font-weight: 600;
  pointer-events: none;
}

.project h2 {
  margin: 0 auto;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .projects-container {
    width: 100%;
    min-width: initial;
  }
  .pro-dec-1 {
    min-width: initial;
    width: 100%;
    display: block;
  }
  .projects-overview {
    min-height: initial;
  }
  .projects-overview h2 {
    font-size: 3.5em;
    position: static;
    margin: auto;
    text-align: center;
    display: block;
  }
  .project {
    transition: 0s;
  }
}