.name {
  color: rgb(241, 241, 241);
  float: left;
  margin: 0;
  position: relative;
  top: 4px;
  left: 9px;
  font-family: 'Cantarell', sans-serif;
  font-weight: 400;
  margin-right: -24%;
}

.name a {
  color: rgb(241, 241, 241);
  text-decoration: none;
}

.main-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid rgb(56, 56, 56);
}

.main-nav ul li {
  display: inline-block;
  width: 120px;
  position: relative;
  right: 8px;
}

.main-nav ul li a {
  display: block;
  margin: auto;
  width: 80px;
  padding: 16px 10px;
  text-decoration: none;
  color: rgb(241, 241, 241);
  font-size: 14px;
  transition: all 0.15s;
}

.main-nav ul li a:hover {
  color: rgb(255, 255, 255);
}

@media only screen and (max-width: 768px) {
  .name {
    width: 100%;
    margin: auto;
    left: 0;
    top: 6px;
  }
  .main-nav {
    min-width: 0px;
  }
  .main-nav ul li {
    right: 0;
  }
}

@media only screen and (max-width: 375px) {
  .main-nav ul li {
    width: 100px;
  }
}

@media only screen and (max-height: 500px) {
  .main-nav {
    min-width: 0px;
  }
}