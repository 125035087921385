.end-project {
  border-bottom: 1px solid rgb(56, 56, 56);
}

.project-info {
  box-sizing: border-box;

  overflow-y: hidden;
  max-height: 0;
	transition-property: all;
	transition-duration: 0.8s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  border-right: 1px solid rgb(56, 56, 56);
  border-left: 1px solid rgb(56, 56, 56);
  background-color: rgb(45, 45, 49);

  position: relative;

  cursor: default;
  width: 100%;
  min-width: 800px;
}

.last {
  border-bottom: 1px solid rgb(56, 56, 56);
}

.project-info .info-background {
  display: grid;
  grid-template-columns: 210px 1.6fr 1fr 2fr;
  grid-template-rows: 215px 1fr;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 700px;
  position: relative;
}

.p-container {
  position: relative;
  font-size: 14px;
}

.expanded .project-info {
  max-height: 645px;
}

.expanded:hover .project {
  cursor: pointer;
}

.info-container {
  position: relative;
  text-align: left;
  display: inline-block;
  margin-top: 22px;

  grid-column: 2/4;
  grid-row: 1/2;
}

.links-area {
  margin-top: 6px;
  float: right;
  width: 50px;
}

.links-area a {
  color: rgb(241, 241, 241);
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 0;
  text-align: right;
}

.vl {
  border-left: 1px solid rgb(56, 56, 56);
  height: 200px;
  position: relative;
  display: inline-block;
}

.hl {
  border-top: 1px solid rgb(74, 74, 74);
  width: 100%;
  position: relative;
  display: inline-block;
}

.tech {
  font-size: 14px;
  font-weight: 200;

  display: inline-block;
  text-decoration: none;
  border: 1px solid rgb(56, 56, 56);
  text-align: center;

  color: rgb(241, 241, 241);
  background-color: rgb(24, 24, 27);

  transition: all 0.2s;

  cursor: pointer;
  outline: none;
}

.tech:hover {
  background-color: rgb(29, 31, 41);
}

.tech:hover .tech-info {
  display: block;
}

.tech-info {
  position: absolute;
  display: none;
  color: rgb(29, 31, 41);
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(29, 31, 41);;
  width: 95px;

  bottom: 20px;
  right: 0;
  left: -2px;

  transition: all 0.3s;
}

.tech-info h3 {
  margin-bottom: 0px;
  margin-top: 25px;
}

.img-container {
  margin-top: 38px;
}

@media only screen and (max-width: 768px) {
  .project-info {
    min-width: initial;
    transition-duration: 0s;
    transition-timing-function: unset;
  }
  .project-info .info-background {
    display: block;
  }
  .expanded .project-info {
    max-height: max-content;
  }
  .info-container {
    padding: 0 4%;
  }
  .info-container h2 {
    text-align: center;
    display: block !important;
  }
  .links-area {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 3%;
    text-align: center;
  }
  .links-area a {
    margin: 2%;
  }
}